import {
  computed, readonly, ref, useContext, useRoute,
} from '@nuxtjs/composition-api';

import { ComposableFunctionArgs, useUiNotification } from '~/composables';
import { Logger } from '~/helpers/logger';
import {
  getPrice as getProductPrice,
} from '~/modules/catalog/product/getters/productGetters';
import { Product } from '~/modules/catalog/product/types';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { useCartStore } from '~/modules/checkout/stores/cart';
import {
  Cart, CartItemInterface, ProductInterface, ProductStockStatus,
} from '~/modules/GraphQL/types';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';

import { UseCartErrors, UseCartInterface } from './useCart';

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<
CART,
CART_ITEM,
PRODUCT
> {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { app } = useContext();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  const { send: sendNotification } = useUiNotification();

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
    });
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({ customQuery = {}, customHeaders = {}, realCart = false } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('***** useCart.load *******');

    try {
      loading.value = true;
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders, realCart });
      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      console.error('!!! useCart/load Error:', err);
      sendNotification({
        id: Symbol('loadCart_error'),
        message: err.message ? `${err.message}. ${app.i18n.t('RefreshPage')}` : app.i18n.t('Login expired, please log in again') as string,
        type: 'danger',
        icon: 'cross',
        persist: false,
      });
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery, customHeaders } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('-- useCart.clear start -- ');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders });

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        if (state.cart) {
          state.cart.total_quantity = totalQuantity;
        }
      });
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty err', err);
      let errMsg = '';
      if (err.message.includes('active')) {
        errMsg = 'Cart Not Active';
      } else {
        errMsg = 'Login expired, please log in again';
      }
      sendNotification({
        id: Symbol('loadTotalQty_error'),
        message: app.i18n.t(errMsg) as string,
        type: 'danger',
        icon: 'cross',
        persist: false,
      });
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({
    product, quantity, productConfiguration, customQuery, customHeaders,
  }): Promise<void> => {
    Logger.debug('useCart.addItem', { product, quantity });

    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        Logger.debug('** No CardID Cookie **');
        await load({ realCart: true });
      }

      const updatedCart = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        customQuery,
        customHeaders,
      });
      if (window && window.dataLayer) {
        const productPriceObj = getProductPrice(product);
        const gtagItems = {
          item_id: product.sku, // required
          item_name: product.name, // required
          item_brand: product.brands ?? '',
          price: productPriceObj.special ?? productPriceObj.regular,
          quantity,
        };
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: apiState.getCurrency(),
            value: productPriceObj.special ?? productPriceObj.regular,
            items: [gtagItems], // required
          }
        });
      }
      error.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
      // await load();
      sendNotification({
        id: Symbol('add_to_cart_success'),
        message: app.i18n.t('Add To Cart Successfully') as string,
        type: 'success',
        icon: 'check',
        persist: false,
      });
    } catch (err) {
      error.value.addItem = err;
      Logger.error('** useCart/addItem Error:', err);
      console.log(err.toString());
      if (err && (err.toString().includes('isn\'t active') || err.toString().includes('Could not find a cart'))) {
        // sendNotification({
        //   id: Symbol('add_to_cart_error'),
        //   message: app.i18n.t('Add To Cart Failed, please try again') as string,
        //   type: 'danger',
        //   icon: 'check',
        //   persist: false,
        // });
        // clear cookie cart
        console.log('* * need to cleat current cart **');
        await clear();
        sendNotification({
          id: Symbol('add_to_cart_error'),
          message: app.i18n.t('please try it again') as string,
          type: 'info',
          icon: 'check',
          persist: false,
        });
      }
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem,
        });
      }
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery, customHeaders }) => {
    Logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;
      await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery,
        customHeaders,
      });

      error.value.removeItem = null;
      // cartStore.$patch((state) => {
      //   state.cart = updatedCart;
      // });
    } catch (err) {
      error.value.removeItem = err;
      console.error('[useCart/removeItem error]:', JSON.stringify(err));
      sendNotification({
        id: Symbol('remove_item_cart_error'),
        message: err.message ?? app.i18n.t('Remove Failed, please try again') as string,
        type: 'danger',
        icon: 'check',
        persist: false,
      });
    } finally {
      await load();
      loading.value = false;
    }
  };

  const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'cart-update-item-qty' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
        });

        error.value.updateItemQty = null;
        await load();
        // cartStore.$patch((state) => {
        //   state.cart = updatedCart;
        // });
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
        sendNotification({
          id: Symbol('update cart qty error'),
          message: err,
          type: 'danger',
          icon: '',
          persist: false,
          title: 'Error!',
        });
      } finally {
        loading.value = false;
      }
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null, isChangingCode = false): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      sendNotification({
        id: Symbol('handleCoupon_error'),
        message: app.i18n.t(errors[0].message) as string,
        persist: false,
        type: 'danger',
        icon: 'check',
        title: 'Error Happened',
      });
      throw errors[0];
    }

    if (updatedCart) {
      if (isChangingCode) {
        cartStore.$patch((state) => {
          state.cart = updatedCart;
        });
      } else {
        await load();
      }
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery, couponCode = null, isChangingCode = false }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery, isChangingCode);
      error.value.removeCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant
        ?.uid;
    }
    const inStock = product?.stock_status === ProductStockStatus.InStock;
    const stockLeft = product?.only_x_left_in_stock === null
      ? true
      : qty <= product?.only_x_left_in_stock;
    return inStock && stockLeft;
  };

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useCart;
export * from './useCart';

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  computed,
  onBeforeMount,
  ref,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';

import {
  useExternalCheckout,
  useImage,
  useProduct,
  useUiNotification,
} from '~/composables';
import { useUser } from '~/modules/account/composables/useUser';
import {
  getPrice
} from '~/modules/catalog/product/getters/productGetters';
import { useCart } from '~/modules/checkout/composables/useCart';
import type { UseCartViewInterface } from '~/modules/checkout/composables/useCartView/useCartView';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import type { BundleCartItem, CartItemInterface, ConfigurableCartItem } from '~/modules/GraphQL/types';
import { ProductStockStatus } from '~/modules/GraphQL/types';

/**
 * Allows loading and manipulating cart view.
 *
 * See the {@link UseCartViewInterface} for a list of methods and values available in this composable.
 */
export function useCartView(): UseCartViewInterface {
  const { localePath, app: { i18n, $vsf } } = useContext();
  const { initializeCheckout } = useExternalCheckout();
  const { getMagentoImage, imageSizes } = useImage();
  const router = useRouter();
  const { getProductPath } = useProduct();
  const {
    cart,
    removeItem,
    updateItemQty,
    load: loadCart,
    loading,
  } = useCart();
  const { isAuthenticated } = useUser();
  const { send: sendNotification, notifications } = useUiNotification();
  onBeforeMount(() => {
    if (!(cart && cart.value?.id)) {
      loadCart();
    }
  });
  const products = computed(() => cartGetters
    .getItems(cart.value)
    .filter(Boolean)
    .map((item) => ({
      ...item,
      product: {
        ...item.product,
        ...[(item as ConfigurableCartItem).configured_variant ?? {}],
        original_sku: item.product.sku,
      },
    })));
  const totals = computed(() => cartGetters.getTotals(cart.value));
  const discount = computed(() => -cartGetters.getDiscountAmount(cart.value));
  const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
  const getAttributes = (product: ConfigurableCartItem) => product.configurable_options || [];
  const getBundles = (product: BundleCartItem) => product.bundle_options?.map((b) => b.values).flat() || [];
  const isRemoveModalVisible = ref(false);
  const itemToRemove = ref<CartItemInterface>();
  const hasOutOfStockItems = () => {
    if (cart && cart?.value) {
      return cart?.value?.items.some((item: any) => item.product.stock_status === 'OUT_OF_STOCK');
    }
    return false;
  };
  const goToCheckout = async (checkoutType?: string) => {
    // console.log('cart', cart);
    const hasOutProduct = hasOutOfStockItems();
    console.log('hasOutProduct', hasOutProduct);
    if (hasOutProduct) {
      sendNotification({
        id: Symbol('product_removed'),
        message: i18n.t('Please remove the sold out products from your  cart') as string,
        type: 'info',
        icon: 'info',
        persist: false,
        title: 'Product removed',
      });
      return;
    }
    // google-analytic:begin checkout
    if (window && window.dataLayer) {
      const gtagItems = products.value.map((productObj) => {
        const productPriceObj = getPrice(productObj.product);
        const itemObj = {
          item_id: productObj.product.sku, // required
          item_name: productObj.product.name, // required
          price: productPriceObj.special ?? productPriceObj.regular,
          quantity: productObj.quantity,
        };
        return itemObj;
      });
      const couponCodeAppliedToCart = computed(() => cartGetters.getAppliedCoupon(cart.value)?.code);
      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          currency: $vsf.$magento.config.state.getCurrency(),
          coupon: couponCodeAppliedToCart.value ?? '',
          value: totals.value.total,
          items: gtagItems, // required
        }
      });
    }
    if (isAuthenticated.value) {
      const redirectUrl = isAuthenticated.value && initializeCheckout({ baseUrl: '/checkout/shipping' });
      await router.push(localePath(redirectUrl));
    } else {
      let redirectUrl = initializeCheckout({ baseUrl: '/checkout/user-account' });
      if (redirectUrl) {
        redirectUrl = `${redirectUrl}?accountType=${checkoutType}`;
      }
      await router.push(localePath(redirectUrl));
    }
  };

  const showRemoveItemModal = ({ product }: { product: CartItemInterface }) => {
    if (notifications.value.length > 0) {
      notifications.value[0].dismiss();
    }

    isRemoveModalVisible.value = true;
    itemToRemove.value = product;
  };

  const removeItemAndSendNotification = async (product: CartItemInterface) => {
    await removeItem({ product });
    isRemoveModalVisible.value = false;

    sendNotification({
      id: Symbol('product_removed'),
      message: i18n.t('{0} has been successfully removed from your cart', {
        0: cartGetters.getItemName(
          product,
        ),
      }) as string,
      type: 'success',
      icon: 'check',
      persist: false,
      title: 'Product removed',
    });
  };

  const delayedUpdateItemQty = debounce(
    (params) => updateItemQty(params),
    400,
  );

  const isInStock = (product: CartItemInterface) => cartGetters.getStockStatus(product) === ProductStockStatus.InStock;

  return {
    showRemoveItemModal,
    removeItemAndSendNotification,
    delayedUpdateItemQty,
    goToCheckout,
    getAttributes,
    getBundles,
    isInStock,
    getMagentoImage,
    getProductPath,
    loading,
    isAuthenticated,
    products,
    isRemoveModalVisible,
    itemToRemove,
    totals,
    totalItems,
    imageSizes,
    discount,
    cartGetters,
  };
}

export default useCartView;
export * from './useCartView';

import { Middleware } from '@nuxt/types';

import { Logger } from '~/helpers/logger';
import { RoutableInterface } from '~/modules/GraphQL/types';
import { usePageStore } from '~/stores/page';

const urlResolverMiddleware: Middleware = async (context) => {
  const pageStore = usePageStore();
  const { path } = context.route;
  let clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');
  const specialLinks = ['/privacy-policy-cookie-restriction-mode', '/privacy-statement', '/mx-privacy-statement',
    '/track-order-info', '/mx-track-order-info', '/about', '/mx-about', '/affiliate-home', '/mx-affiliate-home',
    '/terms-and-conditions', '/mx-terms-and-conditions', '/enable-cookies', '/mx-enable-cookies', '/dmca', '/mx-dmca'
  ];
  if (specialLinks.indexOf(clearUrl) < 0 && clearUrl.indexOf('html') < 0) {
    clearUrl += '.html';
  }

  Logger.info('==> url-resolver: clearUrl:', clearUrl);

  // /product/xxxxxx.html
  if (/^\/product\/.*/.test(clearUrl)) {
    clearUrl = clearUrl.replace('/product', '');
  }
  if (/^\/category\/.*/.test(clearUrl)) {
    clearUrl = clearUrl.replace('/category', '');
  }

  //  ** search module
  if (clearUrl.indexOf('/search') > -1) {
    pageStore.$patch((state) => {
      state.routeData = {
        type: 'SEARCH',
        uid: 'SEC=',
      };
    });
    return;
  }

  const { data, errors } = await context.app.$vsf.$magento.api.route(clearUrl);

  Logger.info('middleware/url-resolver/result', { data, errors });

  const results: RoutableInterface | null = data?.route ?? null;

  if (!results || errors?.length) context.error({ statusCode: 404 });
  pageStore.routeData = results;
};

export default urlResolverMiddleware;

import { VsfContext } from '~/composables/context';
import { Cart, RemoveItemFromCartInput } from '~/modules/GraphQL/types';
import { CustomHeaders, CustomQuery } from '~/types/core';

export const removeItemCommand = {
  execute: async (
    context: VsfContext,
    {
      currentCart,
      product,
      customQuery = { removeItemFromCart: 'cart-remove-item' },
      customHeaders,
    },
  ) => {
    console.log('[Magento]: Remove item from cart', {
      product,
      currentCart,
    });

    const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid);

    if (!item) {
      throw new Error('can\'t find this product');
    }

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: currentCart.id,
      cart_item_uid: item.uid,
    };
    try {
      const { data } = await context.$magento.api.removeItemFromCart(
        removeItemParams,
        customQuery as CustomQuery,
        customHeaders as CustomHeaders,
      );
      console.log('>>> [removeItemFromCart Result]:', JSON.stringify(data));
      // eslint-disable-next-line consistent-return
      return data
        .removeItemFromCart
        .cart as unknown as Cart;
    } catch (error) {
      console.error('!!! [removeItemFromCart Error]:', JSON.stringify(error));
      throw error;
    }
  },
};
